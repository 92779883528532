import React from "react";
import ActionButtons from "../components/ActionButtons";
import FacebookChatIcon from "../components/FacebookChatIcon";
import "../styles/creative.css";
import "../styles/global.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "@fortawesome/fontawesome-free/css/all.min.css";

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      isMobile: false
    };
  }

  componentDidMount() {
    this.setState({ isMobile: this.isMobileDevice() });
    if (this.isMobileDevice()) {
      setInterval(() => {
        this.hideFacebookChat();
      }, 1000);
    }
  }

  isMobileDevice() {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  }

  handleMessengerClick = event => {
    event.preventDefault();
    const isMobile = this.isMobileDevice();
    if (isMobile) {
      this.openMessengerWindow();
    } else {
      try {
        this.showFacebookChat();
      } catch (e) {
        this.openMessengerWindow();
      }
    }
  };

  openMessengerWindow() {
    window.open("https://m.me/plombiersToulousains");
  }

  showFacebookChat() {
    document.querySelector(".fb_dialog_content").classList.add("shake");
    window.setTimeout(() => {
      document.querySelector(".fb_dialog_content").classList.remove("shake");
    }, 1000);
    window.FB.CustomerChat.showDialog();
  }

  hideFacebookChat() {
    window.FB.CustomerChat.hide();
  }

  render() {
    return (
      <div id="top">
        <Header />
        <header>
          <div className="header-content">
            <div className="header-content-inner">
              <h1 id="homeHeading">
                Vos artisans plombiers
                <br />à Toulouse
              </h1>
              <hr className="mg-b-4 light" />
              <div className="flex flex-center flex-md-wrap">
                <div className="header-service">
                  <img
                    src="/img/pictos/support_icon.png"
                    alt="Artisan qualifé"
                    height="30px"
                  />
                  <h3>Prise en charge immédiate</h3>
                </div>
                <div className="header-service">
                  <img
                    src="/img/pictos/contract_icon.png"
                    alt="Plombier dans l'heure"
                    height="30px"
                  />
                  <h3>Devis défini à l'avance</h3>
                </div>
                <div className="header-service">
                  <img
                    src="/img/pictos/watch_icon.png"
                    alt="Qualité"
                    height="30px"
                  />{" "}
                  <h3>Intervention dans l'heure</h3>
                </div>
              </div>
              <section className="pg-t-10 pg-b-5">
                <ActionButtons />
              </section>
            </div>
          </div>
        </header>
        <section className="bg-primary no-padding pg-t-10">
          <div className="container">
            <div className="row flex flex-align-end flex-justify-center flex-md-center flex-md-wrap">
              <div className="flex-md-80 text-center plumber-icon-container">
                <img
                  src="/img/illu_plombier_300x.png"
                  alt="Plombier sur Toulouse"
                  width="300px"
                />
              </div>
              <div className="flex-50 flex-md-80 mg-l-6 pg-b-6 ">
                <h2 className="section-heading">Notre mission</h2>
                <hr className="light left" />
                <p className="text-faded letter-spacing">
                  Si vous avez besoin d'un plombier, c'est que votre journée a
                  mal commencé... Chez PlombiersToulousains.Fr,{" "}
                  <span className="bold white">il nous tient à coeur</span>{" "}
                  qu'elle se finisse bien.
                  <br />
                  <br />
                  <span className="bold white">Efficacité</span>,
                  <span className="bold white"> budget maîtrisé</span> et
                  <span className="bold white"> sourire</span> définissent notre
                  service.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="section-heading">Notre démarche</h2>
                <hr className="primary" />
              </div>
            </div>
          </div>
          <div className="flex flex-justify-space-around flex-align-start text-center flex-md-wrap">
            <div className="service-box flex-35 flex-md-100">
              <img
                src="/img/pictos/picto_diplome_128x.png"
                alt="Artisan qualifé"
                height="100px"
              />
              <h3>Artisans sélectionnés</h3>
              <p className="text-muted mg-t-2">
                Nous vérifions systématiquement les{" "}
                <span className="bold blue-plombier">diplômes</span> et{" "}
                <span className="bold blue-plombier">assurances</span> de nos
                artisans partenaires. De plus, nous ne travaillons qu’avec des
                professionnels ayant au moins{" "}
                <span className="bold blue-plombier">
                  7&nbsp;ans d’expérience
                </span>{" "}
                dans le métier.
              </p>
            </div>

            <div className="service-box flex-35 flex-md-100">
              <img
                src="/img/pictos/handshake.svg"
                alt="Qualité"
                height="100px"
              />{" "}
              <h3>Nos devis nous engagent</h3>
              <p className="text-muted mg-t-2">
                Fini les mauvaises surprises au pas de votre porte… Grâce à
                notre solution de diagnostic à distance,
                <span className="bold blue-plombier">
                  {" "}
                  nous annonçons le montant avant de nous déplacer.
                </span>{" "}
                Si vous l’acceptez, il nous engage.
              </p>
            </div>
          </div>

          <ActionButtons className="mg-t-10" />
        </section>
        <section className="bg-primary" id="about">
          <div className="container">
            <div className="row flex flex-center flex-justify-space-between flex-md-center flex-md-wrap">
              <div className="flex-50 flex-md-80 ">
                <h2 className="section-heading">
                  Notre équipe Diagnostic vous répond
                </h2>
                <hr className="light left" />
                <p className="text-faded letter-spacing">
                  Par <span className="bold white">téléphone</span> ou sur{" "}
                  <span className="bold white">Messenger</span> , nos agents
                  dédiés vous accompagnent pas à pas pour un{" "}
                  <span className="bold white">diagostic précis</span> du
                  problème et pour connaître immédiatement le montant du devis.
                  <br />
                  <br />
                  PlombiersToulousains.Fr est de plus la seule plateforme vous
                  permettant d’
                  <span className="bold white">
                    envoyer des photos et des vidéos
                  </span>{" "}
                  grâce à notre intégration Facebook Messenger.
                </p>
              </div>

              <div className="flex-md-80  mg-l-2 iphone-discussion-container">
                <img
                  src="/img/iPhone_DiscutionType_3_400x.png"
                  alt="Plombier sur Toulouse"
                  width="380px"
                />
              </div>
            </div>
          </div>
          <ActionButtons className="mg-t-10" />
        </section>
        <section id="contact ">
          <div className="container">
            <div className="row text-16">
              <div className="col-lg-8 col-lg-offset-2 text-center">
                <h2 className="section-heading">
                  Nos interventions les plus courantes
                </h2>
                <hr className="primary" />
                <div className="flex flex-center flex-column ">
                  <div className="pricing-row">Débouchage de WC</div>
                  <div className="pricing-row">
                    Réparation de chasse d'eau - WC simple
                  </div>
                  <div className="pricing-row">
                    Recherche d’une fuite du tuyau d'évacuation
                  </div>
                  <div className="pricing-row">
                    Recherche d’une fuite douche et baignoire
                  </div>
                  <div className="pricing-row">
                    Réparation d’une fuite du ballon d’eau chaude
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ActionButtons className="mg-t-10" />
        </section>
        <section className="bg-primary">
          <div className="flex flex-center  flex-md-center flex-md-wrap">
            <div className="flex flex-column flex-center text-center">
              <h2 className="section-heading">Pour vous servir</h2>
              <hr className="light" />

              <p className="text-faded letter-spacing w-65 w-md-80 ">
                Afin de sélectionner les
                <span className="bold white"> meilleurs artisans </span> et vous
                proposer la
                <span className="bold white"> meilleure qualité </span>
                de service, l’équipe PlombiersToulousains.Fr compte
                <span className="bold white"> 5 membres dévoués </span>
                pour en finir avec les mauvaises surprises lors des dépannages !
              </p>
              <div className="flex flex-center flex-wrap mg-t-6">
                <div className="mg-1 flex-30 flex-md-40 flex-sm-80">
                  <img
                    className="rounded"
                    src="img/team/maxime_200x.png"
                    alt="Maxime"
                    width="150px"
                  />

                  <h3>Maxime</h3>
                  <p className="text-faded">
                    Responsable
                    <br /> technique
                  </p>
                </div>

                <div className="mg-1 flex-30 flex-md-40 flex-sm-80">
                  <img
                    className="rounded"
                    src="img/team/baptiste_200x.png"
                    alt="Baptiste"
                    width="150px"
                  />

                  <h3>Baptiste</h3>
                  <p className="text-faded">
                    Designer
                    <br /> Graphiste
                  </p>
                </div>
                <div className="mg-1 flex-30 flex-md-40 flex-sm-80">
                  <img
                    className="rounded"
                    src="img/team/marie-line_200x.png"
                    alt="Marie-Line"
                    width="150px"
                  />
                  <h3>Marie-Line</h3>
                  <p className="text-faded">
                    Agent de
                    <br /> diagnostic
                  </p>
                </div>
                <div className="mg-1 flex-30 flex-md-40 flex-sm-80">
                  <img
                    className="rounded"
                    src="img/team/cherif_200x.png"
                    alt="Chérif"
                    width="150px"
                  />
                  <h3>Chérif</h3>
                  <p className="text-faded">
                    Agent de
                    <br /> diagnostic
                  </p>
                </div>
              </div>
              <a
                href="mailto:jobs@plombierstoulousains.fr"
                className="button-join-us no-hover-decoration mg-t-6"
              >
                Nous rejoindre
              </a>
            </div>
          </div>
        </section>
        {this.state.isMobile ? (
          <FacebookChatIcon handleMessengerClick={this.handleMessengerClick} />
        ) : (
          ""
        )}{" "}
        <Footer />
      </div>
    );
  }
}
