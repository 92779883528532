import React from "react";

import "../styles/creative.css";
import "../styles/global.css";
import "./footer.css";
import version from "../components/Version";

export default class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div>
          <a href="/mentions-legales">Mentions légales</a>&nbsp;-&nbsp;
          <a href="/charte-de-confidentialite">Confidentialité</a>
        </div>
        <div>© Plombiers Toulousains - 2018-2019 - Tous droits réservés</div>
        <div className="invisible">
          {version.number} {version.date}
        </div>
      </footer>
    );
  }
}
