import React from "react";
import MessengerButton from "./MessengerButton";

export default class ActionButtons extends React.Component {
  isMobileDevice() {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  }
  onClickTelButton = event => {
    if (!this.isMobileDevice()) {
      event.preventDefault();
    }
  };

  render() {
    const props = this.props;
    return (
      <div
        className={
          props.className + " flex flex-center flex-md-wrap text-center"
        }
      >
        <a
          href="tel:+335-31-60-05-01"
          onClick={this.onClickTelButton}
          className={
            props.actionClass +
            " flex-250 flex-sm-80 flex-md-60 flex-self-stretch action-box action-tel mg-r-4 mg-md-r-0 no-hover-decoration"
          }
        >
          <i className="fa fa-phone middle" />
          <span className="text-sub">&nbsp;&nbsp;05 31 60 05 01</span>
        </a>
        <MessengerButton
          className={
            props.actionClass +
            " flex-250 flex-sm-80 flex-md-60  flex-self-stretch action-box action-messenger invert"
          }
        />
      </div>
    );
  }
}
