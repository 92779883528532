import React from "react";
import MessengerIcon from "../components/MessengerIcon";

import "../styles/creative.css";
import "../styles/global.css";

export default class Header extends React.Component {
  isMobileDevice() {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  }
  onClickTelButton = event => {
    if (!this.isMobileDevice()) {
      event.preventDefault();
    }
  };

  render() {
    return (
      <nav id="mainNav" className="navbar navbar-default navbar-fixed-top">
        <div className="flex flex-align-center flex-justify-space-between h-100 flex-md-center">
          <div className="navbar-header">
            <a className="navbar-brand page-scroll" href="/#top">
              <img
                src="/img/logo_white_x80.png"
                alt="Plombier Toulouse"
                height="40px"
              />{" "}
            </a>
          </div>

          <div
            className="collapse navbar-collapse mg-r-2 hide-md"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right header-links">
              <li>
                <a
                  className="page-scroll no-padding underline-effect text-size-2"
                  href="tel:+335-31-60-05-01"
                  onClick={this.onClickTelButton}
                >
                  <i className="fa fa-phone" />
                  &nbsp;&nbsp;05 31 60 05 01
                </a>
              </li>
              <li>
                <MessengerIcon />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
