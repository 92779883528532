import React, { Component } from "react";
export default class MessengerButton extends Component {
  isMobileDevice() {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  }

  handleMessengerClick = event => {
    event.preventDefault();
    const isMobile = this.isMobileDevice();
    if (isMobile) {
      this.openMessengerWindow();
    } else {
      try {
        this.showFacebookChat();
      } catch (e) {
        this.openMessengerWindow();
      }
    }
  };

  openMessengerWindow() {
    window.open("https://m.me/plombiersToulousains");
  }

  showFacebookChat() {
    document.querySelector(".fb_dialog_content").classList.add("shake");
    window.setTimeout(() => {
      document.querySelector(".fb_dialog_content").classList.remove("shake");
    }, 1000);
    window.FB.CustomerChat.showDialog();
  }
  render() {
    return (
      <button
        className={this.props.className + " no-style no-hover-decoration"}
        onClick={this.handleMessengerClick}
      >
        <i className="fab fa-facebook-messenger" />
        &nbsp;&nbsp;Messenger
      </button>
    );
  }
}
